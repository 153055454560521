import _ from 'lodash';

/**
 * Filter dynamic list to only show active options
 * @param  {array} listOptions of dynamic list options
 * @param  {object} currentRecord current record object (can be undefined or empty)
 * @param  {string} path path to access object's value to check against (to include if deactivated but used)
 * @return {array} of filtered dynamic list options
 */
export const extractActiveListOptions = (listOptions, currentRecord, path) => {
  return _.filter(listOptions, (option) => {
    return option.active || +option.value === _.get(currentRecord, path);
  });
};
