<template>
  <a-col
    :span="field.span"
    :xs="field.styles.xs"
    :sm="field.styles.sm"
    :md="field.styles.md"
    :lg="field.styles.lg"
    :xl="field.styles.xl"
    :class="field.styles.classes"
  >
    <text-field
      v-if="field.type === 'text'"
      v-bind="field"
      :fieldKey="name"
      :fieldValue="fieldValue"
      @update:fieldValue="$emit('inputChanged', $event)"
    />
    <number-field
      v-if="field.type === 'number'"
      v-bind="field"
      :fieldKey="name"
      :fieldValue="fieldValue"
      @update:fieldValue="$emit('inputChanged', $event)"
    />
    <text-area
      v-else-if="field.type === 'textArea'"
      v-bind="field"
      :fieldKey="name"
      :fieldValue="fieldValue"
      @update:fieldValue="$emit('inputChanged', $event)"
    />
    <check-box
      v-else-if="field.type === 'checkbox'"
      v-bind="field"
      :fieldKey="name"
      :fieldValue="fieldValue"
      @update:fieldValue="$emit('inputChanged', $event)"
    />
    <select-field
      v-else-if="field.type === 'select'"
      v-bind="field"
      :fieldKey="name"
      :fieldValue="fieldValue"
      @update:fieldValue="$emit('inputChanged', $event)"
    />
    <multiple-select-field
      v-else-if="field.type === 'multipleSelect'"
      v-bind="field"
      :fieldKey="name"
      :fieldValue="fieldValue"
      @update:fieldValue="$emit('inputChanged', $event)"
    />
    <switch-field
      v-else-if="field.type === 'switch'"
      v-bind="field"
      :fieldKey="name"
      :fieldValue="fieldValue"
      @update:fieldValue="$emit('inputChanged', $event)"
    />
    <password-field
      v-else-if="field.type === 'password'"
      v-bind="field"
      :fieldKey="name"
      :fieldValue="fieldValue"
      :validateAgainst="validateAgainst"
      @update:fieldValue="$emit('inputChanged', $event)"
      @validateField="$emit('validateField', $event)"
    />
    <upload-field
      v-else-if="field.type === 'file'"
      v-bind="field"
      :fieldKey="name"
      :files-value="fieldValue"
      @update:files-value="$emit('inputChanged', $event)"
    />
  </a-col>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import TextField from '@/components/shared/fields/Text';
import NumberField from '@/components/shared/fields/Number';
import TextArea from '@/components/shared/fields/TextArea';
import CheckBox from '@/components/shared/fields/CheckBox';
import SelectField from '@/components/shared/fields/Select';
import MultipleSelectField from '@/components/shared/fields/MultipleSelect';
import SwitchField from '@/components/shared/fields/Switch';
import PasswordField from '@/components/shared/fields/Password';
import UploadField from '@/components/shared/fields/FileUploader';

export default defineComponent({
  name: 'FormField',
  emits: ['inputChanged', 'validateField'],
  components: {
    TextField, NumberField, TextArea, CheckBox, SelectField, SwitchField,
    PasswordField, UploadField, MultipleSelectField
  },
  props: {
    field: VueTypes.object,
    name: VueTypes.any,
    fieldValue: VueTypes.any,
    validateAgainst: VueTypes.string
  }
});
</script>
