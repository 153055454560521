<template>
  <div>
    <h3>{{$t('views.shipment.itemsValue')}}</h3>
    <app-form
      :fields="fields"
      :rules="formRules"
      :validateOnRuleChange="true"
      :trackTouched="true"
      :triggerFormSubmit="submitForm"
      @submitFormTriggered="submitForm = false"
      @submitted="handleSubmit"
      @input-changed="inputChanged"
      @forceUpdated="handleForceUpdate"
      @fieldsTouched="$emit('fieldsTouched')"
      :submitButton="{hidden: true}"
    >
      <template #actions>
        <steps-form-navigation
          :loading="loading"
          @nextStep="submitForm = true"
          @previousStep="$emit('previousStep')"
          :nextLabel="'actions.saveAndNext'"
        />
      </template>
    </app-form>
  </div>
</template>

<script>
import {
  defineComponent, reactive, ref, watch, toRefs
} from 'vue';
import _ from 'lodash';
import VueTypes from 'vue-types';
import {
  requiredNumber, nonZero, required
} from '@/helpers/validationRules';
import StepsFormNavigation from '@/components/shared/form/StepsFormNavigation';
import {useRoute} from 'vue-router';


export default defineComponent({
  name: 'ItemsValueForm',
  props: {
    totalValue: VueTypes.number.def(0),
    shipment: VueTypes.object.def({}),
    loading: VueTypes.bool.def(false)
  },
  components: {StepsFormNavigation},
  emits: ['submit:shipment', 'previousStep', 'fieldsTouched'],
  setup(props, context) {
    const route = useRoute();
    const clone = route.name === 'ClientCloneShipment' || route.name === 'ClientShipmentsFormCloneShipmentRequest';
    const items = ref([]);
    const submitForm = ref(false);

    const fields = reactive({
      totalValue: {
        type: 'number',
        label: 'models.shipment.attributes.totalValue',
        disabled: true,
        fieldValue: 0,
        styles: {md: 8}
      },
      currency: {
        type: 'text',
        label: 'models.shipment.attributes.currency',
        fieldValue: '$',
        disabled: true,
        styles: {md: 8}
      },
      quoteDeadline: {
        type: 'datePicker',
        label: 'models.shipment.attributes.quoteDeadline',
        fieldValue: undefined,
        styles: {md: 8},
        futureDate: true
      },
      cifValue: {
        type: 'number',
        label: 'models.shipment.attributes.cifValue',
        fieldValue: 0,
        min: 0,
        trackable: true,
        styles: {md: 13}
      },
      calculateCif: {
        type: 'checkbox',
        label: 'models.shipment.attributes.calculateCif',
        fieldValue: false,
        styles: {md: 6, classes: 'inline-checkbox'},
        trackable: true
      },
      insuranceCost: {
        type: 'number',
        label: 'models.shipment.attributes.insuranceCost',
        fieldValue: 0,
        min: 0,
        styles: {md: 12},
        hidden: true
      },
      freightCost: {
        type: 'number',
        label: 'models.shipment.attributes.freightCost',
        fieldValue: 0,
        min: 0,
        styles: {md: 12},
        hidden: true
      },
      documents: {
        type: 'file',
        label: 'models.shipment.attributes.documents',
        fieldValue: [],
        styles: {md: 24},
        multiple: true
      }
    });

    const formRules = reactive({
      cifValue: [requiredNumber, nonZero],
      insuranceCost: [{...requiredNumber, required: false}, nonZero],
      freightCost: [{...requiredNumber, required: false}, nonZero],
      quoteDeadline: [{...required, type: 'object'}]
    });

    const {totalValue, shipment} = toRefs(props);
    watch(totalValue, (value) => {
      fields.totalValue.fieldValue = value;
      fields.totalValue['forceUpdate'] = true;
    });

    watch(shipment, (shipment) => {
      handleCalculateCif(shipment.calculateCif);
      const fieldsToFill = shipment.calculateCif ? ['freightCost', 'insuranceCost', 'calculateCif', 'quoteDeadline'] :
        ['cifValue', 'calculateCif', 'quoteDeadline'];
      _.forEach(fieldsToFill, (key) => {
        fields[key].fieldValue = shipment[key];
        fields[key]['forceUpdate'] = true;
      });

      fields.documents.fieldValue = _.map(shipment['documents'], (document) => {
        return {
          uid: document.id,
          name: document.filename,
          status: 'done',
          url: `${process.env.VUE_APP_GCE_BASE_URL}${document.url}`
        };
      });
      fields.documents['forceUpdate'] = true;
      fields.quoteDeadline['futureDate'] = false;
      if (clone) {
        fields.quoteDeadline['fieldValue'] = undefined;
      }
    });

    const inputChanged = (event) => {
      const {key, value} = event;
      if (key === 'calculateCif') {
        handleCalculateCif(value);
      } else if (key === 'cifValue' && value > 0) {
        fields.calculateCif['forceUpdate'] = true;
        fields.calculateCif.fieldValue = false;
        handleCalculateCif(false);
      }
    };

    const handleCalculateCif = (value) => {
      _.forEach(['freightCost', 'insuranceCost'], (key) => {
        fields[key].fieldValue = 0;
        fields[key].hidden = !value;
        fields[key]['forceUpdate'] = true;
        formRules[key][0].required = value;
        formRules[key][1].min = value ? 1 : 0;
      });
      formRules.cifValue[0].required = !value;
      formRules.cifValue[1].min = value ? 0 : 1;
      if (value) {
        fields.cifValue.fieldValue = 0;
        fields.cifValue['forceUpdate'] = true;
      }
    };

    const handleForceUpdate = (keys) => _.forEach(keys, (key) => fields[key]['forceUpdate'] = false);

    const handleSubmit = (data) => {
      submitForm.value = false;
      const formData = new FormData();

      _.forEach(data, (value, key) => {
        if (key === 'documents' && value) {
          _.forEach(value, (file) => {
            file instanceof File ? formData.append('documents[]', file) :
              formData.append('documents[][id]', file.uid);
          });
        } else {
          formData.append(key, value || '');
        }
      });
      context.emit('submit:shipment', formData);
    };

    return {
      submitForm,
      fields,
      formRules,
      items,
      inputChanged,
      handleForceUpdate,
      handleSubmit
    };
  }
});
</script>
