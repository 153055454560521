<template>
  <confirm-modal
    :title="$t('views.confirm.leavePage.title')"
    :confirmMessage="$t('views.confirm.leavePage.message')"
    :showConfirm="confirmModalVisible"
    @confirm:ok="changeRoute"
    @confirm:cancel="confirmModalVisible = false"
  >
  </confirm-modal>
  <div v-if="!$_.isEmpty(clientProductItems)">
    <items-table-form
      v-show="currentStep === shipmentFormSteps.items"
      :items="clientProductItems"
      :shipment="shipment"
      @fieldsTouched="fieldsTouched = true"
      @submit:items="saveState"
    />
    <shipping-info-form
      v-show="currentStep === shipmentFormSteps.shippingInformation"
      :currentDestination="currentDestination"
      :shipment="shipment"
      @fieldsTouched="fieldsTouched = true"
      :categoriesIds="categoriesIds"
      @submit:shippingInfo="saveState"
      @previousStep="currentStep -= 1"
    />
    <items-value-form
      v-show="currentStep === shipmentFormSteps.itemsValue"
      :totalValue="totalValue"
      :shipment="shipment"
      :loading="loading"
      @fieldsTouched="fieldsTouched = true"
      @submit:shipment="handleSubmit"
      @previousStep="currentStep -= 1"
    />
  </div>
</template>

<script>
import {
  defineComponent, ref, reactive, computed, watch
} from 'vue';
import ItemsTableForm from '@/components/shipments/ItemsTableForm';
import ShippingInfoForm from '@/components/shipments/ShippingInfoForm';
import ItemsValueForm from '@/components/shipments/ItemsValueForm';
import ConfirmModal from '@/components/modals/ConfirmModal';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import _ from 'lodash';
import {onBeforeRouteUpdate, onBeforeRouteLeave} from 'vue-router';
import VueTypes from 'vue-types';

export default defineComponent({
  inheritAttrs: false,
  name: 'ShipmentsForm',
  components: {
    ItemsTableForm,
    ShippingInfoForm,
    ItemsValueForm,
    ConfirmModal
  },
  props: {
    clientProductItems: VueTypes.array,
    currentDestination: VueTypes.object.def(undefined),
    shipmentRequestId: VueTypes.string,
    shipment: VueTypes.object.def({})
  },
  emits: ['shipments:submitted'],
  setup(props, context) {
    const {dispatch} = useStore();
    const router = useRouter();
    const clone = computed(() => router.currentRoute.value.name === 'ClientCloneShipment');

    const shipmentFormSteps = {
      items: 1,
      shippingInformation: 2,
      itemsValue: 3
    };

    const categoriesIds = computed(() => {
      return state.items_attributes ? _.map(state.items_attributes, (item) => item.categoryId) : [];
    });

    const currentStep = ref(shipmentFormSteps.items);
    watch(currentStep, () => window.scrollTo(0, 0));

    const state = reactive({});
    const fieldsTouched = ref(false);

    const saveState = (data) => {
      Object.assign(state, {...state, ...data});
      currentStep.value += 1;
    };

    const totalValue = computed(() => {
      let sum = 0;
      _.forEach(state[_.snakeCase('itemsAttributes')], (item) => {
        sum += item.quantity * item[_.snakeCase('valuePerItem')];
      });
      return _.round(sum, 2);
    });

    const loading = ref(false);
    const handleSubmit = async (formData) => {
      if (clone.value) formData.append('cloned', true);
      loading.value = true;
      _.forEach(state, (attribute, key) => {
        if (key === _.snakeCase('itemsAttributes') || key === _.snakeCase('dimensionsAndWeights')) {
          _.forEach(attribute, (record, index) => {
            _.forOwn(record, (value, attributeKey) => {
              formData.append(`${key}[${index}][${attributeKey}]`, value || '');
            });
          });
        } else {
          formData.append(key, attribute || '');
        }
      });
      const status = _.isEmpty(props.shipment) || clone.value ?
        await dispatch('createShipment', {formData, shipmentRequestId: props.shipmentRequestId}) :
        await dispatch('updateShipment', {
          formData,
          shipmentRequestId: props.shipmentRequestId,
          shipmentId: props.shipment._jv.id
        });
      loading.value = false;
      if (`${status}`.startsWith(2)) {
        fieldsTouched.value = false;
        context.emit('shipments:submitted');
      }
    };

    const confirmModalVisible = ref(false);
    const targetRoute = ref();
    const changeRoute = () => {
      fieldsTouched.value = false;
      router.push({path: targetRoute.value});
    };

    const confirmLeave = (to) => {
      if (to.fullPath) {
        if (fieldsTouched.value) {
          confirmModalVisible.value = true;
          targetRoute.value = to.fullPath;
          return false;
        } else {
          return true;
        }
      } else if (fieldsTouched.value) {
        to?.preventDefault();
        to.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', confirmLeave);
    onBeforeRouteLeave((to, from) => confirmLeave(to));
    onBeforeRouteUpdate(async (to, from) => confirmLeave(to));

    return {
      shipmentFormSteps,
      currentStep,
      state,
      fieldsTouched,
      totalValue,
      loading,
      confirmModalVisible,
      changeRoute,
      saveState,
      handleSubmit,
      clone,
      categoriesIds
    };
  }
});
</script>

<style lang="scss">
button.remove-item-btn {
  width: 100% !important;
}
</style>
