<template>
  <div>
    <h3>{{$t('views.shipment.selectItems')}}</h3>
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <table-style-wrapper>
          <table-wrapper class="table-responsive">
            <a-form
              ref="formRef"
              name="sDash_validation-form"
              layout="vertical"
              :model="state"
              :validateOnRuleChange="false"
            >
              <a-table :columns="columns"
                :data-source="$_.sortBy(itemsData, (item) => item.shipmentItemId === undefined)"
                :rowKey="(record) => `${record._jv.id}`"
                :pagination="false"
              >
                <template #descriptionOfGoods="{ record }">
                  <a-tooltip>
                    <template #title>{{record.descriptionOfGoods}}</template>
                    <div class="ellipsis-col">{{record.descriptionOfGoods}}</div>
                  </a-tooltip>
                </template>

                <template #productCategory="{ record }">
                  {{record._jv.relationships.productCategory.attributes.value}}
                </template>
                <template #productNumber="{ record }">
                  {{record.productNumber}}
                </template>
                <template #comment="{ record }">
                  <a-tooltip>
                    <template #title>{{record.comment}}</template>
                    <div class="ellipsis-col">{{record.comment}}</div>
                  </a-tooltip>
                </template>
                <template #countryOfOrigin="{ record }">
                  <a-tooltip>
                    <template #title>{{record.countryOfOriginName}}</template>
                    <div>{{record.countryOfOrigin}}</div>
                  </a-tooltip>
                </template>
                <template #itemType="{ record }">
                  <a-tooltip>
                    <template #title>{{record.itemType}}</template>
                    <div>{{record.itemType}}  </div>
                  </a-tooltip>
                </template>
                <template #checkbox="{ record }">
                  <div class="editable-cell checkbox-field-col">
                    <div class="editable-cell-input-wrapper">
                      <check-box
                        :field-value="includedItems[record._jv.id]"
                        @update:fieldValue="updateIncluded(record._jv.id, $event)"
                      />
                      <number-field
                        :hidden="true"
                        v-model:field-value="state[`productItemId_${record._jv.id}`]"
                        :fieldKey="`productItemId_${record._jv.id}`"
                      />
                      <number-field
                        :hidden="true"
                        v-model:field-value="state[`id_${record._jv.id}`]"
                        :fieldKey="`id_${record._jv.id}`"
                      />
                    </div>
                  </div>
                </template>
                <template #valuePerItem="{ record }">
                  <div class="editable-cell field-col">
                    <div class="editable-cell-input-wrapper">
                      <number-field
                        v-bind="fields.number"
                        :disabled="!includedItems[record._jv.id]"
                        :rules="includedItems[record._jv.id] ? fields.number.rules : undefined"
                        :field-value="state[`valuePerItem_${record._jv.id}`]"
                        @update:fieldValue="inputChanged(`valuePerItem_${record._jv.id}`, $event)"
                        :fieldKey="`valuePerItem_${record._jv.id}`"
                      />
                    </div>
                  </div>
                </template>
                <template #quantity="{ record }">
                  <div class="editable-cell field-col">
                    <div class="editable-cell-input-wrapper">
                      <number-field
                        v-bind="fields.number"
                        :disabled="!includedItems[record._jv.id]"
                        :rules="includedItems[record._jv.id] ? fields.number.rules : undefined"
                        :field-value="state[`quantity_${record._jv.id}`]"
                        @update:fieldValue="inputChanged(`quantity_${record._jv.id}`, $event)"
                        :fieldKey="`quantity_${record._jv.id}`"
                        :allowDecimal="false"
                      />
                    </div>
                  </div>
                </template>
                <template #dataSheet="{ record }">
                  <div class="editable-cell upload-field-col">
                    <div class="editable-cell-input-wrapper file-uploader-details">
                      <file-uploader
                        :disabled="true"
                        :fieldKey="`dataSheet_${record._jv.id}`"
                        v-bind="fields.fileUploader"
                        :files-value="state[`dataSheet_${record._jv.id}`]"
                        @update:filesValue="inputChanged(`dataSheet_${record._jv.id}`, $event)"
                      />
                    </div>
                  </div>
                </template>
              </a-table>

              <div class="sDash_form-action float-button-right">
                <a-button class="btn-signin mr-10"
                          size="small"
                          type="primary"
                          @click="submitForm">
                  <span>{{$t('actions.next')}}</span>
                  <sdFeatherIcons type="arrow-right" size="15" />
                </a-button>
              </div>
            </a-form>
          </table-wrapper>
        </table-style-wrapper>
      </VerticalFormStyleWrap>
    </FormValidationWrap>
  </div>
</template>

<script>
import {
  computed, defineComponent, reactive, toRefs, ref, watch
} from 'vue';
import {useI18n} from 'vue-i18n';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import NumberField from '@/components/shared/fields/Number';
import CheckBox from '@/components/shared/fields/CheckBox';
import FileUploader from '@/components/shared/fields/FileUploader';
import {displayServerErrorsInToaster} from '@/helpers/toasters';
import {
  requiredNumber, nonZero, required
} from '@/helpers/validationRules';
import {scrollToFirstError} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'ShipmentItemsTableForm',
  components: {
    VerticalFormStyleWrap,
    FormValidationWrap,
    TableStyleWrapper,
    TableWrapper,
    NumberField,
    CheckBox,
    FileUploader
  },
  props: {
    items: VueTypes.array.def([]),
    shipment: VueTypes.object.def({})
  },
  emits: ['submit:items', 'fieldsTouched'],
  setup(props, context) {
    const {t} = useI18n();

    const columns = computed(() => [
      {
        title: '',
        slots: {customRender: 'checkbox'}
      },
      {
        title: t('models.productItem.attributes.hsCode'),
        dataIndex: 'hsCode'
      },
      {
        title: t('models.productItem.attributes.comment'),
        dataIndex: 'comment',
        slots: {customRender: 'comment'}
      },
      {
        title: t('models.productItem.attributes.productCategory'),
        dataIndex: 'productCategory',
        slots: {customRender: 'productCategory'}
      },
      {
        title: t('models.productItem.attributes.productNumber'),
        dataIndex: 'productNumber',
        slots: {customRender: 'productNumber'}
      },
      {
        title: t('models.productItem.attributes.descriptionOfGoods'),
        dataIndex: 'descriptionOfGoods',
        slots: {customRender: 'descriptionOfGoods'}
      },
      {
        title: t('models.productItem.attributes.countryOfOrigin'),
        dataIndex: 'countryOfOrigin',
        slots: {customRender: 'countryOfOrigin'}
      },
      {
        title: t('models.productItem.attributes.itemType'),
        slots: {customRender: 'itemType'}
      },
      {
        title: t('models.productItem.attributes.valuePerItem'),
        slots: {customRender: 'valuePerItem'}
      },
      {
        title: t('models.productItem.attributes.quantity'),
        slots: {customRender: 'quantity'}
      },
      {
        title: t('models.productItem.attributes.dataSheet'),
        slots: {customRender: 'dataSheet'},
        align: 'center'
      }
    ]);

    const formRef = ref();
    const {items, shipment} = toRefs(props);
    const state = reactive({});
    const includedItems = reactive({});

    const setItemsId = () => _.forEach(items.value, (item) => state[`productItemId_${item._jv.id}`] = item._jv.id);

    const attachDataSheet = () => {
      _.forEach(items.value, (item) => {
        if (item.dataSheet) {
          state[`dataSheet_${item._jv.id}`] = [{
            uid: item.dataSheet.id,
            name: item.dataSheet.filename,
            status: 'done',
            url: `${process.env.VUE_APP_GCE_BASE_URL}${item.dataSheet.url}`
          }];
        }
      });
    };

    const setStateFields = (shipmentItem) =>{
      _.forEach(['valuePerItem', 'quantity', 'itemType'], (field) => {
        state[`${field}_${shipmentItem.productItemId}`] = shipmentItem[field];
      });
    };

    const initState = () => {
      _.forEach(items.value, (item) => item.shipmentItemId = undefined);
      Object.assign(state, {});
      Object.assign(includedItems, {});
      setItemsId();
      attachDataSheet();
    };

    watch(shipment, () => {
      if (!_.isEmpty(shipment.value) && shipment.value._jv) {
        initState();

        _.forEach(shipment.value._jv.relationships.items, (shipmentItem) => {
          const itemIndex = _.findIndex(items.value, (item) => +item._jv.id === shipmentItem.productItemId);
          includedItems[shipmentItem.productItemId] = true;
          if (itemIndex < 0) return;
          items.value[itemIndex]['shipmentItemId'] = shipmentItem._jv ? shipmentItem._jv.id : undefined;
          state[`id_${shipmentItem.productItemId}`] = shipmentItem._jv ? shipmentItem._jv.id : undefined;
          setStateFields(shipmentItem);
        });
      }
    }, {deep: true});

    const fields = {
      number: {
        min: 0,
        rules: [requiredNumber, nonZero],
        size: 'small'
      },
      select: {
        rules: [required],
        options: [
          {label: 'Software', value: 'SW'},
          {label: 'Hardware', value: 'HW'}
        ],
        size: 'small'
      },
      fileUploader: {
        multiple: false,
        iconButton: true,
        hideUploadIcon: true
      }
    };

    const inputChanged = (key, value) => {
      fields.fileUploader.iconButton = !(value && Object.keys(value).length);
      context.emit('fieldsTouched');
      state[key] = value;
    };

    const updateIncluded = (key, value) => {
      context.emit('fieldsTouched');
      includedItems[key] = value;
    };

    const submitForm = (e) => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(() => {
          if (_.isEmpty(includedItems) || !_.includes(includedItems, true)) {
            displayServerErrorsInToaster([t('messages.error.noItemsSelected')]);
          } else {
            const data = _.map(items.value, (item) => {
              if (includedItems[item._jv.id]) {
                const itemFields = {};
                _.forEach(['productItemId', 'valuePerItem', 'quantity', 'itemType'], (attribute) => {
                  itemFields[_.snakeCase(attribute)] = state[`${attribute}_${item._jv.id}`] || '';
                });

                itemFields['id'] = state[`id_${item._jv.id}`] || '';
                itemFields['categoryId'] = item._jv.relationships.productCategory.id;
                return itemFields;
              } else {
                return undefined;
              }
            });

            if (!_.isEmpty(shipment.value) && shipment.value._jv?.id) {
              _.forEach(shipment.value._jv.relationships.items, (item) => {
                if (includedItems[item.productItemId]) return;
                data.push({id: item._jv.id, _destroy: 1});
              });
            }

            context.emit('submit:items', {[_.snakeCase('itemsAttributes')]: _.compact(data)});
          }
        }).catch(({errorFields}) => {
          scrollToFirstError(formRef, errorFields);
        });
    };

    initState();

    return {
      formRef,
      state,
      itemsData: items,
      columns,
      fields,
      includedItems,
      updateIncluded,
      inputChanged,
      submitForm
    };
  }
});
</script>

<style lang="scss" scoped>
.sDash_form-action {
  padding-top: 30px !important;
}
</style>
<style lang="scss">
.file-uploader-details{
  .ant-upload-list-item-card-actions .anticon {
    display: none !important;;
  }
}
</style>
