<template>
  <div>
    <h3>{{$t('views.shipment.shippingInfo')}}</h3>
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <a-form
          ref="formRef"
          name="sDash_validation-form"
          layout="vertical"
          :model="formState"
          :validateOnRuleChange="false"
        >
          <a-row :gutter="[32,0]">
            <template v-for="(field, key) in fields" :key="key">
              <template v-if="$_.isArray(field)">
                <a-col :xs="24">
                  <template v-for="(dimension, index) in field" :key="index">
                    <div class="card-grid-wrap">
                      <a-card-grid :style="{ width: '100%', textAlign: 'left', padding: '24px' }">
                        <a-row type="flex" justify="space-around" align="middle">
                          <a-col :xs="24" :md="22">
                            <a-row :gutter="[32, 0]">
                              <template v-for="(dimensionField, dimensionKey) in dimension" :key="dimensionKey">
                                <form-fields
                                  :field="dimensionField"
                                  :name="['dimensionsAndWeights', index, dimensionKey]"
                                  :fieldValue ="formState.dimensionsAndWeights[index][dimensionKey]"
                                  @input-changed="inputChanged($event, dimensionKey, index)" />
                              </template>
                            </a-row>
                          </a-col>
                          <a-col v-if="true" :xs="24" :md="2">
                            <sdButton
                              v-if="field.length > 1"
                              outlined
                              class="ml-10 btn-icon remove-item-btn"
                              type="default"
                              shape="circle"
                              :disabled="field.length === 1"
                              @click="removeGroup(dimension)"
                              :tooltip="$t('actions.remove')"
                            >
                              <sdFeatherIcons type="minus-circle" stroke="#E83557" size="14" html-type="button" />
                            </sdButton>
                          </a-col>
                        </a-row>
                      </a-card-grid>
                    </div>
                  </template>
                  <a-card-grid
                      :style="{ width: '100%', textAlign: 'center', padding: '24px' }">
                    <a-form-item>
                      <sdButton size="small" type="primary" @click="addGroup" outlined html-type="button"
                        :disabled="!formState.packagingQuantity ||
                        fields.dimensionsAndWeights.length >= formState.packagingQuantity"
                        :tooltip="fields.dimensionsAndWeights.length >= formState.packagingQuantity ?
                          $t('messages.help.increaseQuantity') : ''"
                      >
                        <sdFeatherIcons type="plus-circle" size="14" />
                        <span>{{$t('actions.add')}}</span>
                      </sdButton>
                    </a-form-item>
                  </a-card-grid>
                </a-col>
              </template>
              <template v-else>
                <form-fields :field="field" :name="key"
                  @input-changed="inputChanged($event, key)"
                  :fieldValue ="formState[key]" />
              </template>
            </template>
            <steps-form-navigation
              @nextStep="submitForm"
              @previousStep="$emit('previousStep')"
            />
          </a-row>
        </a-form>
      </VerticalFormStyleWrap>
    </FormValidationWrap>
  </div>
</template>

<script>
import {
  defineComponent, reactive, ref, computed, watch, toRefs
} from 'vue';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import VueTypes from 'vue-types';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import StepsFormNavigation from '@/components/shared/form/StepsFormNavigation';
import FormFields from '@/components/shared/form/FormFields';
import {FieldStyles} from '@/components/shared/fields/styles';
import {
  weightUnits, packagingUnits, temperatureUnits
} from '@/constants';
import {
  requiredNumber, nonZero, required, minRange
} from '@/helpers/validationRules';
import {extractActiveListOptions} from '@/helpers/dynamicListsOptions';
import {getCitiesOfACountry} from '@/helpers/citiesOptions';
import {scrollToFirstError} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'ShippingInfoForm',
  emits: ['submit:shippingInfo', 'previousStep', 'fieldsTouched'],
  components: {
    VerticalFormStyleWrap, FormValidationWrap, FormFields, StepsFormNavigation
  },
  props: {
    currentDestination: VueTypes.object.def(undefined),
    shipment: VueTypes.object.def(undefined),
    categoriesIds: VueTypes.array.def([])
  },
  setup(props, context) {
    const {t} = useI18n();

    const {dispatch, getters} = useStore();
    dispatch('getList', 'PACKAGING_TYPES');
    dispatch('getCountries', {exclude_sanctioned: true});
    const packagingTypes = computed(() => getters.packagingTypes);
    const route = useRoute();
    const clone = computed(() => route.name === 'ClientCloneShipment');

    const shipmentRequestId = computed(() => route.params.shipmentRequestId);
    const shipmentRequest = computed(() => getters.shipmentRequest);
    const getShipmentRequest = async () => {
      if (shipmentRequestId.value) {
        dispatch('toggleLoading', true);
        await dispatch('getShipmentRequest', shipmentRequestId.value);
        dispatch('toggleLoading', false);
      }
    };

    if (!shipmentRequest.value.shipmentType) {
      getShipmentRequest();
    }

    const shipmentIdentifierExist = ref(false);
    const formState = reactive({});
    const fields = reactive({
      destinationCountry: {
        type: clone.value ? 'select' : 'text',
        label: 'models.shipment.attributes.destinationCountry',
        fieldValue: clone.value ? '' : props.currentDestination.label,
        options: computed(() => getters.countries),
        disabled: !clone.value,
        enableSearch: true,
        rules: [required],
        styles: new FieldStyles({md: 8})
      },
      deliveryCity: {
        type: 'select',
        fieldValue: '',
        label: 'models.shipment.attributes.deliveryCity',
        options: computed(() => {
          const country = clone.value ? formState['destinationCountry'] : props.currentDestination.value;
          return getCitiesOfACountry(country);
        }
        ),
        rules: [required],
        size: 'large',
        styles: new FieldStyles({md: 8}),
        enableSearch: true
      },
      endUserName: {
        type: 'text',
        label: 'models.shipment.attributes.endUserName',
        fieldValue: '',
        rules: [required],
        styles: new FieldStyles({md: 8})
      },
      preferredAgentId: {
        type: 'select',
        label: 'models.shipment.attributes.preferredAgent',
        fieldValue: '',
        disabled: computed(() => (shipmentRequest.value.published && shipmentIdentifierExist.value) && !clone.value),
        allowClear: true,
        options: computed(() => {
          if (getters.preferredAgents) {
            return _.map(getters.preferredAgents.data, (agent) => {
              return {
                label: agent.attributes.name, value: agent.id
              };
            });
          } else {
            return [];
          }
        }),
        styles: new FieldStyles({md: 8})
      },
      landingPort: {
        type: 'text',
        label: 'models.shipment.attributes.landingPort',
        fieldValue: '',
        rules: [required],
        styles: new FieldStyles({md: 8})
      },
      deliveryAddressLineOne: {
        type: 'text',
        label: 'models.shipment.attributes.deliveryAddressLineOne',
        fieldValue: '',
        rules: [required],
        maxLength: 80,
        styles: new FieldStyles({md: 8})
      },
      temperatureControl: {
        type: 'switch',
        label: 'models.shipment.attributes.temperatureControl',
        fieldValue: false,
        styles: new FieldStyles({md: 6, classes: 'inline-switch'})
      },
      temperatureControlValue: {
        type: 'number',
        placeholder: 'models.shipment.attributes.temperature',
        fieldValue: '',
        hidden: true,
        rules: [{...requiredNumber, required: false}],
        styles: new FieldStyles({md: 5, classes: 'field-without-label'})
      },
      temperatureControlUnit: {
        type: 'select',
        placeholder: 'models.shipment.attributes.unit',
        options: [
          {label: t('models.shipment.attributes.temperatureUnits.fahrenheit'), value: temperatureUnits.FAHRENHEIT},
          {label: t('models.shipment.attributes.temperatureUnits.celsius'), value: temperatureUnits.CELSIUS}
        ],
        rules: [{...required, required: false}],
        fieldValue: undefined,
        hidden: true,
        styles: new FieldStyles({md: 4, classes: 'field-without-label'})
      },
      packagingTypeId: {
        type: 'select',
        label: 'models.shipment.attributes.packagingType',
        fieldValue: '',
        options: computed(() => {
          return extractActiveListOptions(packagingTypes.value, shipment.value, 'packagingTypeId');
        }),
        rules: [required],
        styles: new FieldStyles({md: 12})
      },
      packagingQuantity: {
        type: 'number',
        allowDecimal: false,
        min: 0,
        label: 'models.shipment.attributes.packagingQuantity',
        fieldValue: '',
        rules: [requiredNumber, minRange(1)],
        styles: new FieldStyles({md: 12})
      },
      dimensionsAndWeights: []
    });

    _.forEach((fields), (field, key) => formState[key] = field.fieldValue);
    formState['dimensionsAndWeights'] = [];
    const preferredAgents = computed(() => getters.preferredAgents);

    watch(() => props.categoriesIds, async () => {
      await dispatch('getPreferredAgents', {
        preferred: true, service_type: shipmentRequest.value.shipmentType,
        destination_country: props.currentDestination.value,
        source_country: shipmentRequest.value.shippingFrom,
        product_category_ids: props.categoriesIds
      });
      if (clone.value || !preferredAgents.value.data.length) {
        formState['preferredAgentId'] = undefined;
      }
    });

    const {shipment} = toRefs(props);
    watch(shipment, (shipment) => {
      shipmentIdentifierExist.value = !!shipment.identifier;
      _.forEach(fields, (field, key) => {
        if (key === 'packagingTypeId' || (key === 'preferredAgentId' && !clone.value)) {
          field.fieldValue = formState[key] = shipment[key] ? `${shipment[key]}` : field.fieldValue;
        } else if (key !== 'dimensionsAndWeights' && key !== 'preferredAgentId') {
          field.fieldValue = formState[key] = shipment[key] || field.fieldValue;
        } else if (key === 'dimensionsAndWeights') {
          if (_.size(shipment.dimensionsAndWeights) > 0) {
            fields.dimensionsAndWeights = [];
            formState.dimensionsAndWeights = [];
            _.forEach(shipment.dimensionsAndWeights, (group) => {
              addGroup(undefined, group);
            });
            adjustPackagingQuantity();
          }
        }

        if (shipment.temperatureControl) {
          _.forEach(['temperatureControlValue', 'temperatureControlUnit'], (key) => {
            fields[key].hidden = false;
            fields[key].rules[0].required = true;
          });
        }
      });
      formState['destinationCountry'] = clone.value ? '' : props.currentDestination.label;
    });

    const adjustPackagingQuantity = () => {
      fields['packagingQuantity'].rules[1] = minRange(Object.keys(formState.dimensionsAndWeights).length);
    };

    const addGroup = (e, item) => {
      const disableUnits = fields.dimensionsAndWeights.length !== 0;
      if (e) e.preventDefault();
      fields.dimensionsAndWeights.push({
        weight: {
          label: 'models.shipment.attributes.weight',
          type: 'number',
          fieldValue: item ? _.toNumber(item.weight) : 0,
          placeholder: 'models.shipment.attributes.weight',
          rules: [requiredNumber, nonZero],
          styles: new FieldStyles({
            md: 12, lg: 4, classes: 'weight-fields weight-value'
          })
        },
        weightUnit: {
          type: 'select',
          fieldValue: item ? item[_.snakeCase('weightUnit')] : undefined,
          placeholder: 'models.shipment.attributes.unit',
          options: [
            {label: t('models.shipment.attributes.weightUnits.kg'), value: weightUnits.KG},
            {label: t('models.shipment.attributes.weightUnits.lbs'), value: weightUnits.LBS}
          ],
          rules: [required],
          disabled: disableUnits,
          styles: new FieldStyles({
            md: 12, lg: 4, classes: 'weight-fields weight-unit field-without-label'
          })
        },
        length: {
          type: 'number',
          label: 'models.shipment.attributes.dimensions',
          bottomLabel: 'models.shipment.attributes.length',
          placeholder: 'models.shipment.attributes.length',
          fieldValue: item ? _.toNumber(item.length) : 0,
          rules: [requiredNumber, nonZero],
          min: 0,
          styles: new FieldStyles({
            md: 6, lg: 4, classes: 'dimension-fields dimension-value'
          })
        },
        width: {
          type: 'number',
          fieldValue: item ? _.toNumber(item.width) : 0,
          bottomLabel: 'models.shipment.attributes.width',
          placeholder: 'models.shipment.attributes.width',
          rules: [requiredNumber, nonZero],
          min: 0,
          styles: new FieldStyles({
            md: 6, lg: 4, classes: 'dimension-fields dimension-value field-without-label'
          })
        },
        height: {
          type: 'number',
          bottomLabel: 'models.shipment.attributes.height',
          fieldValue: item ? _.toNumber(item.height) : 0,
          placeholder: 'models.shipment.attributes.height',
          rules: [requiredNumber, nonZero],
          min: 0,
          styles: new FieldStyles({
            md: 6, lg: 4, classes: 'dimension-fields dimension-value field-without-label'
          })
        },
        dimensionsUnit: {
          type: 'select',
          fieldValue: item ? item[_.snakeCase('dimensionsUnit')] : undefined,
          placeholder: 'models.shipment.attributes.unit',
          rules: [required],
          disabled: disableUnits,
          styles: new FieldStyles({
            md: 6, lg: 4, classes: 'dimension-fields dimension-unit field-without-label'
          }),
          options: [
            {label: t('models.shipment.attributes.packagingUnits.cm'), value: packagingUnits.CM},
            {label: t('models.shipment.attributes.packagingUnits.inch'), value: packagingUnits.INCH}
          ]
        }
      });
      const addedItemIndex = fields.dimensionsAndWeights.length - 1;
      formState.dimensionsAndWeights.push({});
      _.forEach(fields.dimensionsAndWeights[addedItemIndex], (field, key) => {
        formState.dimensionsAndWeights[addedItemIndex][key] = field.fieldValue;
      });
    };

    const removeGroup = (item) => {
      const index = fields.dimensionsAndWeights.indexOf(item);
      if (index !== -1) {
        fields.dimensionsAndWeights.splice(index, 1);
        formState.dimensionsAndWeights.splice(index, 1);
      }
      if (fields.dimensionsAndWeights.length >= 1) {
        fields.dimensionsAndWeights[0]['dimensionsUnit']['disabled'] = false;
        fields.dimensionsAndWeights[0]['weightUnit']['disabled'] = false;
      }
      adjustPackagingQuantity();
    };

    const inputChanged = async (value, key, index) => {
      if (key === 'packagingQuantity') {
        adjustPackagingQuantity();
      }
      if (key === 'destinationCountry') {
        formState['preferredAgentId'] = undefined;
        formState['deliveryCity'] = undefined;
        await dispatch('getPreferredAgents', {
          preferred: true, service_type: shipmentRequest.value.shipmentType,
          destination_country: value,
          source_country: shipmentRequest.value.shippingFrom,
          product_category_ids: props.categoriesIds
        });
      }
      context.emit('fieldsTouched');
      index === undefined ? formState[key] = value : formState.dimensionsAndWeights[index][key] = value;

      if (key === 'temperatureControl') {
        _.forEach(['temperatureControlUnit', 'temperatureControlValue'], (field) => {
          fields[field].rules[0].required = value;
          fields[field].fieldValue = formState[field] = undefined;
          fields[field].hidden = !value;
        });
      }
      _.forEach(Object.keys(formState['dimensionsAndWeights']).slice(1), (key) => {
        formState['dimensionsAndWeights'][key]['weightUnit'] = formState['dimensionsAndWeights'][0]['weightUnit'];
        formState['dimensionsAndWeights'][key]['dimensionsUnit'] = formState['dimensionsAndWeights'][0]['dimensionsUnit'];
      });
      _.forEach(fields.dimensionsAndWeights.slice(1), (field) => {
        field['weightUnit']['fieldValue'] = formState['dimensionsAndWeights'][0]['weightUnit'];
        field['dimensionsUnit']['fieldValue'] = formState['dimensionsAndWeights'][0]['dimensionsUnit'];
      });
    };

    const formRef = ref();
    const submitForm = (e) => {
      formRef.value
        .validate()
        .then(() => {
          const data = {};
          _.forEach(formState, (field, key) => {
            if (key !== 'dimensionsAndWeights') {
              data[_.snakeCase(key)] = field;
            } else {
              data[_.snakeCase(key)] = _.map(field, (measurments) => {
                const values = {};
                _.forOwn(measurments, (value, measurmentKey) => values[_.snakeCase(measurmentKey)] = value);
                return values;
              });
            }
          });
          if (!clone.value) {
            data[_.snakeCase('destinationCountry')] = props.currentDestination.value;
          }
          context.emit('submit:shippingInfo', data);
        }).catch(({errorFields}) => {
          scrollToFirstError(formRef, errorFields);
        });
    };

    addGroup();

    return {
      packagingTypes,
      formRef,
      fields,
      formState,
      addGroup,
      removeGroup,
      inputChanged,
      submitForm
    };
  }
});
</script>


<style lang="scss" scoped>
button.remove-item-btn {
  width: 100% !important;
}

.weight-fields.weight-value {
  padding-right: 0px !important;
}

.weight-fields.weight-unit, .dimension-fields.dimension-unit {
  padding-right: 40px !important;
}

.dimension-fields.dimension-value {
  padding-right: 0px !important;
}

.sDash_form-action {
  padding-top: 30px !important;
  padding-right: 30px !important;
}
</style>
