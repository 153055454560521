<script>
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {
  createVNode, defineComponent, toRefs, watch
} from 'vue';
import {Modal} from 'ant-design-vue';
import VueTypes from 'vue-types';

export default defineComponent({
  props: {
    title: VueTypes.string.def(''),
    confirmMessage: VueTypes.string.def(''),
    showConfirm: VueTypes.bool.def(false)
  },
  render: () => null,
  emits: ['confirm:ok', 'confirm:cancel'],
  setup(props, context) {
    const {showConfirm} = toRefs(props);
    watch(showConfirm, (value) => {
      if (value) {
        Modal.confirm({
          title: props.title,
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode(
            'div',
            {
              style: 'color:red;'
            },
            props.confirmMessage
          ),
          onOk() {
            context.emit('confirm:ok');
          },
          onCancel() {
            context.emit('confirm:cancel');
          }
        });
      }
    });
  }
});
</script>
